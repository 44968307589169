/*
  import { miscellaneousReceiptsStore } from '@/services/MiscellaneousReceipts/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await miscellaneousReceiptsStore.dispatch ('getMiscellaneousReceiptsList', {
      miscellaneousReceiptID
    });
  */

  async getMiscellaneousReceiptList({}, { hoaID }) {
    console.log('in getMiscellaneousReceiptList...' + hoaID)
    try {
      const result = await kms.get(`/AccountsReceivable/MiscellaneousReceipt/List?hoaID=${hoaID}`)

      if (isDebug == true)
        console.debug('getMiscellaneousReceiptList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The miscellaneous receipt list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMiscellaneousReceiptDistributionList({}, { miscellaneousReceiptID }) {
    console.log('in getMiscellaneousReceiptDistributionList...' + miscellaneousReceiptID)
    try {
      const result = await kms.get(
        `/AccountsReceivable/MiscellaneousReceiptDistribution/List?miscellaneousReceiptID=${miscellaneousReceiptID}`
      )

      if (isDebug == true)
        console.debug('getMiscellaneousReceiptDistributionList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The miscellaneous receipt distribution list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMiscellaneousReceiptById({}, { miscellaneousReceiptID }) {
    try {
      console.debug('in getMiscellaneousReceiptById...')
      const result = await kms.get(
        `/AccountsReceivable/MiscellaneousReceipt/${miscellaneousReceiptID}`
      )

      if (isDebug == true) console.debug('getMiscellaneousReceiptById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this miscellaneous receipt.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const miscellaneousReceiptsStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
