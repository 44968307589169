<template>
  <div :style="styles_.tile" class="w-full lg:w-1/3 p-2">
    <div :style="styles_.innerTile" class="bg-white p-10 rounded shadow">
      <div>
        <i style="width: 60px; height: 60px;" :class="`icon-${data.icon}`" />
      </div>

      <h1 class="text-base text-xl text-blue-800 font-semibold">{{ data.title }}</h1>
      <h2 class="text-base">{{ data.subtitle }}</h2>

      <ul class="mt-8" v-if="Array.isArray(data.list)">
        <li
          v-for="(item, index) in data.list"
          :key="index"
          class="bg-white odd:bg-gray-200 rounded-sm p-2 flex"
          :style="styles.listItem"
        >
          <div class="w-1/5 text-blue-200 font-bold">{{ item.col1 }}</div>
          <div :style="styles.listItemRight" class="text-left">{{ item.col2 }}</div>
        </li>
      </ul>

      <div :style="styles_.dropdown" v-if="data.button.dropdown">
        <b-dropdown aria-role="list" class="outline-none focus:border-none mt-8">
          <button
            class="rounded-sm border border-gray-400 px-4 py-2"
            :style="styles.dropdownButton"
            slot="trigger"
            slot-scope="{ active }"
          >
            <span class="relative"></span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="data.button.path"
              >{{ data.button.title }}</router-link
            >
          </button>

          <span v-if="data.button.dropdown && data.button.dropdown != undefined">
            <b-dropdown-item
              v-for="(item, index) in data.button.dropdown"
              :key="index"
              aria-role="listitem"
            >
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </b-dropdown-item>
          </span>
        </b-dropdown>
      </div>

      <div
        class="pt-2"
        v-if="data.hrefList && data.hrefList != undefined"
        :style="{ marginLeft: '24px' }"
      >
        <ul v-if="data.isAlternatingRowColors && data.isAlternatingRowColors == true">
          <li
            class="bg-white odd:bg-gray-200 rounded-md p-2 flex"
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <div
              class="w-1/6 text-blue-400 font-bold"
              style="text-align: center; vertical-align: center;"
            >
              {{ item.prefix }}
            </div>
            <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ul>
        <span v-else>
          <li
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <router-link :to="item.path" class="font-bold href-overflow-wrap">
              {{ item.title }}
            </router-link>
          </li>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import _merge from 'lodash/merge'

export default {
  name: 'FinancialReportCard',

  props: {
    styles: {
      type: Object,
      default() {
        return {}
      }
    },

    data: {
      icon: String,
      prefix: String,
      title: String,
      subtitle: String,
      financialSummary: Object,
      assetSummary: Object,
      balanceSummaryData: Object,
      list: [],
      hrefList: [],
      isAlternatingRowColors: false,
      button: {
        title: String,
        path: String,
        dropdown: []
      }
    }
  },

  data: function() {
    // text bubble content
    var difference = '?'

    // % slider number
    var difference_appearance = 0

    if (typeof this.data.financialSummary === 'object') {
      const operating = _get(this, ['data', 'financialSummary', 'operating'], null)
      const reserve = _get(this, ['data', 'financialSummary', 'reserve'], null)

      if (typeof operating === 'number' && typeof reserve === 'number') {
        difference = ((operating / reserve) * 100).toFixed(2)
        difference_appearance = difference
      }
    }

    return {
      difference,
      difference_appearance,
      styles_: _merge(
        {},
        {
          dropdown: {
            position: 'absolute',
            bottom: '2.5rem',
            left: 0,
            width: '100%',
            justifyContent: 'center'
          },
          dropdownButton: {
            display: 'flex',
            'align-items': 'center'
          },
          financialSummary: {
            marginBottom: '0'
          },
          assetSummary: {
            marginBottom: '4rem'
          },
          listItem: {
            'align-items': 'center'
          },
          listItemRight: {
            width: '75%'
          },
          textBubble: {
            top: '20px',
            left: '-78.5px',
            color: '#FFF'
          },
          tile: {},
          innerTile: {
            position: 'relative',
            height: '100%',
            paddingBottom: '7rem'
          },
          upArrow: {
            position: 'absolute',
            top: '-5px',
            left: '50%',

            width: 0,
            height: 0,
            'border-left': '5px solid transparent',
            'border-right': '5px solid transparent',

            'border-bottom': '5px solid #2baae1'
          }
        },
        this.styles
      )
    }
  }
}
</script>
