/*
  import { apStatisticsStore } from '@/services/ApStatistics/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { result } = await apStatisticsStore.dispatch ('getApStatistics', {
      hoaID
    });
  */

  async getApStatistics({}, { hoaID }) {
    try {
      console.debug('in getApStatistics...')
      const result = await kms.get(`/AccountsPayable/APStatistics/GetCurrent?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getApStatistics=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the A/P Statistics.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const apStatisticsStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
