<template>
  <PageContent :title="$t('title')" accounting-dashboard>
    <div class="section about has-text-centered" tests-id="management-dashboard">
      <div class="container">
        <div class="flex flex-wrap -mx-2" :style="styles.tilesContainer">
          <AccountsReceivableCard :data="receivable" :styles="cardStyles" />
          <AccountsPayableCard :data="payable" :styles="cardStyles" />
          <GeneralLedgerCard :data="generalledger" :styles="cardStyles" />
          <AssetsCard :data="assets" :styles="cardStyles" />
          <FinancialReportCard :data="financials" :styles="cardStyles" />
          <BudgetBuilderCard :data="budgetbuilder" :styles="cardStyles" />
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script type="es6">
import { mapGetters, mapState } from 'vuex'
import PageContent from '@/containers/PageContent'
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { apStatisticsStore } from '@/services/ApStatistics/store'
import { miscellaneousReceiptsStore } from '@/services/MiscellaneousReceipts/store'
import { budgetStore } from '@/services/Budgets/store'

// Components
import AccountsReceivableCard from '@/components/cards/AccountsReceivableCard'
import AccountsPayableCard from '@/components/cards/AccountsPayableCard'
import GeneralLedgerCard from '@/components/cards/GeneralLedgerCard'
import AssetsCard from '@/components/cards/AssetsCard'
import FinancialReportCard from '@/components/cards/FinancialReportCard'
import BudgetBuilderCard from '@/components/cards/BudgetBuilderCard'

import moment from 'moment'

export default {
  name: 'AccountingDashboard',

  components: {
    AccountsReceivableCard,
    AccountsPayableCard,
    GeneralLedgerCard,
    AssetsCard,
    FinancialReportCard,
    BudgetBuilderCard,
    PageContent
  },

  data() {
    const today = moment()

    return {
      isDebug: true,
      formatDateFn: function(value) {
        return value && value != undefined
          ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
              .format('MM-DD-YYYY')
              .toString()
          : ''
      },
      budgetAccountPath: '/budgetbuilder',
      aprows: [],
      styles: {
        tilesContainer: {
          display: "flex",
          "flex-direction": "row",
          "flex-wrap": "wrap",
          width: "100%"
        }
      },
      receivable: {
        icon: 'accountsreceivable',
        title: 'Accounts Receivable',
        subtitle: '',
        isAlternatingRowColors: true,
        hrefList: [
          { prefix: '', title: 'Homeowners who Owe Funds', path: '/ar-homeownersowefees' },
          { prefix: '', title: 'Homeowners who Owe Assessments', path: '/ar-homeownersowefees?status=assessments' },
          { prefix: '', title: 'Homeowners who Owe Non-Assessments', path: '/ar-homeownersowefees?status=nonassessments' },
          { prefix: '', title: 'Miscellaneous Receipts', path: '/ar-miscellaneousreceipts' }
        ],
        list: [],
        button: {
        }
      },
      payable: {
        icon: 'accountspayable',
        title: 'Accounts Payable',
        subtitle: 'Statistics calculated ' + today
            .format('MM-DD-YYYY')
            .toString(),
        isAlternatingRowColors: true,
        hrefList: [
            { prefix: '0', title: 'Invoices received within last 30 days', path: '/ap-invoicesthirtydays/thirty' },
            { prefix: '0', title: 'Invoices pending Coding', path: '/ap-invoicespendingcoding/pending' },
            { prefix: '0', title: 'Approved invoices Pending Board Approval', path: '/ap-invoicespendingapproval/approval' },
            { prefix: '0', title: 'Approved Invoices Pending Posting', path: '/ap-invoicespendingposting/post' }
        ],
        list: [],
        button: {
        }
      },
      generalledger: {
        icon: 'generalledger',
        title: 'General Ledger',
        subtitle: '',
        financialSummary: {
          totalIncome: 0,
          totalExpense: 0,
        },
        isAlternatingRowColors: true,
        hrefList: [
            { prefix: '', title: 'Browse G/L', path: '/accounts' },
            { prefix: '', title: 'Manage Chart of Accounts', path: '/managechartofaccounts' },
            { prefix: '', title: 'Post to G/L', path: '/posttoledger' }
        ],
        list: [],
        button: {
        }
      },
      assets: {
        icon: 'assets',
        title: 'Bank Accounts',
        subtitle: '',
        list: [],
        assetSummary: {
          operating: 0,
          reserve: 0,
          workingCapital: 0
        },
        hrefList: [
            { prefix: '', title: 'View Bank Accounts', path: '/bankAccounts' }
        ],
        button: {
        }
      },
      financials: {
        icon: 'financialreports',
        title: 'Financial Reports',
        subtitle: '',
        list: [
          { col1: '', col2: 'Balance Sheet' },
          { col1: '', col2: 'Year to Date' },
          { col1: '', col2: 'Monthly' },
          { col1: '', col2: 'Custom' }
         ],
        hrefList: [
            { prefix: '', title: 'Run Financial Reports', path: '/reports/financial' },
            { prefix: '', title: 'Completed Monthly Financial Reports', path: '/reportsFinancial' }
        ],
        button: {
        }
      },
      budgetbuilder: {
        icon: 'budgetbuilder',
        title: 'Budget Builder',
        subtitle: '',
        list: [
          { col1: '', col2: 'View All Accounts' },
          { col1: '', col2: 'View Detailed Statistics' }
         ],
        hrefList: [
            { prefix: '', title: 'View Budget Builder', path: '/budgetbuilder' },
            { prefix: '', title: 'View Current Active Budget', path: '/budgetbuilder' }
        ],
        button: {
        }
      },
    }
  },
  async created() {
    const fromSignIn = this.$route.query.fromsignin ? true : false

    if(fromSignIn)
    {
      const returned = await twoFactorAuthentication
      .dispatch('checkFirstUseMultiFactor')

      if(this.isDebug === true) console.debug('isPromptFirstLogin=' + returned.result )

      if(returned.result === false)
      {
        this.$router.push({
          name: 'promptmultifactor'
        })
      }
    }

    await this.computeApTotalsFromList()
    await this.loadArTotals()
    await this.loadAssetTotals()
    await this.loadGlTotals()
    await this.loadCurrentEffectiveBudgetAccount()
  },

  methods: {
    async loadCurrentEffectiveBudgetAccount() {
      try {
        await budgetStore.dispatch ('getCurrentEffectiveBudget', {
          hoaID: this.currentHoaId,
        }).then(({list}) => {
          if(list)
          {
                if(list && list.budgetID)
                {
                  console.debug('getCurrentEffectiveBudget=' + JSON.stringify(list))
                  this.budgetbuilder.hrefList[1].path = `budgetaccounts/${list.budgetID}`
                }
          }
        })



      } catch (e) {
        console.debug('error in method for retrieving budgets:' + e)
      }
    },
    async computeApTotalsFromList()
    {
      await apStatisticsStore.dispatch ('getApStatistics', {
        hoaID: this.currentHoaId
      }).then(({result}) => {
        console.debug('getApStatistics=' + JSON.stringify(result))
        if(result)
        {
          if(result.numInvoicesReceivedLast30Days)
          {
            this.payable.hrefList[0].prefix = result.numInvoicesReceivedLast30Days
          }

          if(result.numInvoicesPendingCoding)
          {
            this.payable.hrefList[1].prefix = result.numInvoicesPendingCoding
          }

          if(result.numInvoicesPendingApproval)
          {
            this.payable.hrefList[2].prefix = result.numInvoicesPendingApproval
          }

          if(result.numInvoicesPendingPosting)
          {
            this.payable.hrefList[3].prefix = result.numInvoicesPendingPosting
          }
        }
      })
    },

    async loadArTotals()
    {
      await this.$store.dispatch('accounting/getAccountsReceivableStatistics', {

      }).then(() => {
        if (this.accountsReceivableStatistics)
        {
          this.receivable.hrefList[0].prefix = this.accountsReceivableStatistics.numHomeownersOweFunds
          this.receivable.hrefList[1].prefix = this.accountsReceivableStatistics.numHomeownersOweAssessments
          this.receivable.hrefList[2].prefix = this.accountsReceivableStatistics.numHomeownersOweNonAssessments
        }
      })

      try {
        await miscellaneousReceiptsStore
        .dispatch('getMiscellaneousReceiptList', {
          hoaID: this.currentHoaId
        })
        .then(({ list }) => {
          if (list) {
            this.receivable.hrefList[3].prefix = list.length
          }
        })
      } catch (e) {
        console.error(e)
      }
    },

    async loadAssetTotals()
    {
      await this.$store.dispatch('accounting/getAssetAccountBalanceList', {

      }).then(() => {
        console.log('then...' + JSON.stringify(this.assetAccountBalances))
        if (this.assetAccountBalances)
        {
          Array.from(this.assetAccountBalances).forEach(e => {
            if(e)
            {
              console.log('e=' + JSON.stringify(e))

              if(e.fundType === 'Operating')
              {
                this.assets.assetSummary.operating = e.balanceAmount
              }
              else if(e.fundType === 'Reserves')
              {
                this.assets.assetSummary.reserve = e.balanceAmount
              }
              else if(e.fundType === 'Working Capital Accounts')
              {
                this.assets.assetSummary.workingCapital = e.balanceAmount
              }
            }
          })
        }
      })
    },

    async loadGlTotals()
    {
      const payload = {
        year: 'current'
      }

      if(this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      await this.$store.dispatch('accounting/getAnnualIncomeExpenseByDates', {
        payload: payload
      }).then(() => {
        if (this.annualBalances)
        {
          this.generalledger.financialSummary.totalIncome = this.annualBalances.incomeBalanceAmount < 0 ? (this.annualBalances.incomeBalanceAmount * (-1)) : this.annualBalances.incomeBalanceAmount
          this.generalledger.financialSummary.totalExpense = this.annualBalances.expenseBalanceAmount
        }
      })
    },
  },

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapGetters('accounting', ['accountsPayableApInvoiceList']),
    ...mapGetters('accounting', ['accountsReceivableStatistics']),
    ...mapGetters('accounting', ['assetAccountBalances']),
    ...mapGetters('accounting', ['annualBalances']),
    ...mapGetters('budgets', ['budgetList']),
    ...mapState({
      breakpoint: state => state.win.breakpoint
    }),
    ...mapGetters('user', ['currentHoaId']),
    thirtyCount() {
      return this.lastThirtyDays.reduce((acc, item) => (item.apInvoiceID ? acc + 1 : acc + 0), 0)
    },
    cardStyles () {
      return {
        innerTile: {
          ...(this.breakpoint === 'mobile' ? {
            padding: '12px'
          } : {})
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        title: 'Accounting'
      }
    }
  }
}
</script>

<style></style>
